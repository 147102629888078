import React from 'react'
import './Hero.css'
import image from '../assets/hero.jpg'
import billing_icon from '../assets/billing-icon.png'
import business_icon from '../assets/business-icon.png'

const Hero = () => {
  return (
    <div>
    <div className='hero-main'>
    
     <div className="hero-left">
     </div>
     <div className='year-box'> 
           <p>25 <br />Years <br /> experience</p>
          </div>
          
     <div className="hero-right">
        <h1>Our Mission is to provide a <br />comprehensive medical <br />billing services at <br />competitive prices.</h1>
        <p className='para'>A penny saved is a penny earned. Take a closer look as we <br /> have exclusively designed our billing service features to help <br /> you earn more and save more.</p>

        <div className="box">
            <div className="billing">
                <div className="billing-box">
                    <img src={billing_icon} alt="" />
                </div>
                    <p>Medical Billing</p>

            </div>
            <div className="business">
            <div className="business-box">
                    <img src={business_icon} alt="" />
                </div>
                    <p>Business <br /> Consultancy</p>
            </div>
        </div><hr />
         <p  className="call-para">Call Us: +1 302-244-8517</p>        

     </div>
          </div>
          
    </div>
  )
}

export default Hero