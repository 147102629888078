import React from 'react'
import './css/Contact.css'
import Contact_first_block from '../components/all-pages-first-block/Contact_first_block'
import mobile_icon from '../components/assets/mobile-icon.png'
import email_icon from '../components/assets/email-icon.png'
import address_icon from '../components/assets/address-icon.png'

const Contact = () => {
  return (
    <div>
      <Contact_first_block />
      <div className="contact-box">
        <div className="contact-left">
            <h1>If You Have Any Query, <br /> Please Contact Us</h1><br /><br /><br />
            <h3>USA</h3>
            <div className="address">
              <img src={address_icon} alt="" />
              <p>728 Rosemary Way Newark DE 19713</p>
            </div>
            <div className="address">
              <img src={mobile_icon} alt="" />
              <p>+1 302-244-8517</p>
            </div>
            <div className="address">
              <img src={email_icon} alt="" />
              <p>info@mkpro.co.uk</p>
            </div>
        </div>
        <div className="contact-right">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6230846.985486517!2d-77.40139834937622!3d40.31115158502513!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c7aa000205277f%3A0xeed4417e95cafcbe!2s728%20Rosemary%20Wy%2C%20Newark%2C%20DE%2019713%2C%20USA!5e0!3m2!1sen!2s!4v1737302580992!5m2!1sen!2s" width="550" height="550" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  )
}

export default Contact