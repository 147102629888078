import React from 'react'
import './Hero_nav.css'
import mobile_icon from '../assets/mobile-icon.png'
import email_icon from '../assets/email-icon.png'
import facebook_icon from '../assets/facebook-icon.png'
import twitter_icon from '../assets/twitter-icon.png'
import linkedin_icon from '../assets/linkedin-icon.png'
import instagram_icon from '../assets/instagram-icon.png'


const Hero_nav = () => {
  return (
    <div className='main'>
       <div className="left">
          <img src={mobile_icon} alt="" />
          <p>+1 302-244-8517    </p>
          <img src={email_icon} alt="" />
          <p>info@mkpro.co.uk</p>
       </div>
       <div className="right">
         <img src={facebook_icon} alt="" />
         <img src={twitter_icon} alt="" />
         <img src={linkedin_icon} alt="" />
         <img src={instagram_icon} alt="" />
       </div>
    </div>
  )
}

export default Hero_nav